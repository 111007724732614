import { PaymentModel, PAYMENT_ARG, PaymentSystem } from "@/models/paymentModel"
import apiPayment from "./api/api-payment"
import httpUtil from "./api/util/http-util"

export default class PaymentService {
  constructor(repo) {
    this.repo = repo
  }
  async list(params, id) {
    try {
      let res =  await this.repo.list(params, id)
      if(!res) throw new Error(httpUtil.processError('Loading system price error'))
      let items = res.map(item => new PaymentSystem(item))
      return items
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listPaymentAmount(params, id) {
    try {
      let res =  await this.repo.listPaymentAmount(id)
      if(!res) throw new Error(httpUtil.processError('Loading system price error'))
      let items = res.map(item => new PaymentSystem(item))
      return items
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async create(ID, data) {
    try {
      let _data = {
        candidate: ID,
        [PAYMENT_ARG.LABEL]: data.label,
        [PAYMENT_ARG.VALUE]: data.subject ? data.value: null, 
        [PAYMENT_ARG.SUBJECT]: data.subject ? data.subject : '',
        [PAYMENT_ARG.CONTENT]: data.content? data.content: '',
        [PAYMENT_ARG.NOTES]: data.notes
      }
      let res =  await this.repo.create( _data)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createRefund(ID, data) {
    try {
      let res =  await this.repo.createRefund(ID, {
        amount: +data.amount
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async cancel(ID) {
    try {
      let res =  await this.repo.cancel(ID)
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }


}