import auth from "@/services/CRMinitAuth"

const listPaymentAmount = (id) => {
  return auth.get(`${process.env.VUE_APP_CRM_API}candidates/actions/${id}/payment_labels/`)
}

const list = () => {
  return auth.get(`${process.env.VUE_APP_CRM_API}payments/payment_labels/`)
}

const create = data => {
  return auth.post(`${process.env.VUE_APP_CRM_API}payments/`, data)
}

const createRefund = (ID, data) => {
  console.log(data)
  return auth.post(`${process.env.VUE_APP_CRM_API}payments/${ID}/create_refund/`, data)
}

const cancel = ID => {
  return auth.post(`${process.env.VUE_APP_CRM_API}payments/${ID}/cancel/`)
}

export default {
  list,
  create,
  cancel,
  createRefund,
  listPaymentAmount
}
