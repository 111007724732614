import api from "@/services/api/api-payment"
export default class PaymentRepo {
  async list(params, id) {
    return await api.list(id)
  }

  async listPaymentAmount(params, id) {
    return await api.listPaymentAmount(params,id)
  }

  async create(data) {
    return await api.create(data)
  }

  async createRefund(ID, data) {
    return await api.createRefund(ID, data)
  }

  async cancel(ID) {
    return await api.cancel(ID)
  }
}