<script>
import Svc from "@/services/payments-service";
import Repo from "@/models/PaymentRepo"
import errorsMixin from '@/mixins/errors.mixin';
import { PAYMENT_STATUS } from '../../../../../../models/paymentModel';

export default {
  render() {
    return this.$scopedSlots.default({
      showWhenHasCreatedPayment: this.showWhenHasCreatedPayment,
      showing: this.showing,
      send: this.cancelPayment,
      skip: this.skip,
      toggle: this.toggle,
      cancelPayment: this.cancelPayment,
      processing: this.processing
    })
  },
  props: {
    payments: Array,
    ID: Number
  },

  mixins: [errorsMixin],


  data: () => ({
    showing: false,
    processing: false,
    paymentData: {},
    svc: new Svc(
      new Repo()
    )
  }),

  methods: {
    showWhenHasCreatedPayment(data) {
      this.paymentData = data
      let openPayment = this.payments.find(payment => payment.status === PAYMENT_STATUS.OPEN)
      if(!openPayment) {
        this.notifyToContinueCreating()
        return
      } 
      this.toggle()
    },

    toggle() {
      this.showing = !this.showing
    },

    async cancelPayment(ID) {
      try {
        this.processing = true
        await this.svc.cancel(ID)
        this.$notify({text: 'Payment canceled', type: 'success'})
        this.$emit('canceled', ID)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    skip() {
      this.toggle()
      this.notifyToContinueCreating()
    },

    notifyToContinueCreating() {
      this.$emit('end', this.paymentData)
    },

    _failBtnClicked() {
      this.updateValue(false)
    },

    _okBtnClicked() {
      this.$emit('click:cancel')
      this.updateValue(false)
    },
        
  }
}
</script>